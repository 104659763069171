<template>
  <div class="main__Login">
    <div class="div_center__Login">
      <h1 class="text-center mb-5">Sistema de Importadores</h1>
      <v-card>
        <v-card-title>
          Panel Administrativo
        </v-card-title>
        <v-card-text>
          <v-text-field label="Usuario"> </v-text-field>
          <v-text-field
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Contraseña"
          >
          </v-text-field>
          <v-row>
            <v-col>
              <v-btn color="primary" :to="{ name: 'Inicio' }" rounded block>
                INGRESAR
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="error" rounded block>
                SALIR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    show1: false,
  }),
};
</script>
<style>
.main__Login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #34495e;
  height: 100vh;
}

.div_center__Login {
  height: 300px;
  width: 600px;
  border-radius: 10px;
}

.div_center__Login h1 {
  color: white;
}

@media (max-width: 800px) {
  .div_center__Login {
    height: 30vh;
    width: 80vw;
  }

  .div_center__Login h1 {
    font-size: 1.5rem;
  }
}
</style>
